import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ContentLayout from '../../../components/ContentLayout';
import AcademyNavigation from '../../../components/academy/AcademyNavigation';
import TuitionNavigation from '../../../components/academy/TuitionNavigation';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <ContentLayout title="Tuition &amp; Financing" subTitle="Tuition" nav={<AcademyNavigation />} subNav={<TuitionNavigation />}>
    {children}
  </ContentLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Program Tuition`}</h3>
    {
      /* prettier-ignore */
    }
    <p>{`Tuition for the Immersive program is a flat fee of `}<strong parentName="p">{`$14,900`}</strong>{` for the entire program. The tuition for the Night Shift program is $10,000. The tuition does not include any costs associated with parking, meals, or any other fees not specifically provided for in this catalog or the enrollment agreement. The tuition does not include any costs that may be required for, or associated with, the purchase of a laptop computer.`}</p>
    <h3>{`Payment`}</h3>
    {
      /* prettier-ignore */
    }
    <p>{`After being accepted in to the program, you can reserve a seat in our course with a deposit of $1,000. The entire amount of the deposit will be applied to your tuition balance, and the remaining funds will be due before the first day of class unless alternative financing arrangements are made. Payment can be made by check, money order, debit, or credit card.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      